import CostAttributionList from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/cost-attribution-list/index'
import BelongToProjectList from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/belong-to-project-list/1.0.0/index.vue'
import getEvectionAllType from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.0.0'
import DynamicFormPc from '../../dynamic-form-pc/import_latest_version_module'

export default {
    props: {
        evectionFormParams: {
            type: Object,
            default: () => {}
        },
        businessRuleControl: {
            type: Number,
            default: 1
        },
    },
    data() {
        const validateFeeAffiliationName = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请选择成本归属'))
            } else {
                callback()
            }
        };
        const validateProjectName = (rule, value, callback) => {
            if (this.evectionParams.centerCode != null && this.evectionParams.centerCode.indexOf('E') != -1) {
                if (!value) {
                    callback(new Error('请选择归属项目'))
                } else {
                    callback()
                }
                return;
            }
            callback();
        };
        return {
            parameterListBox: [],
            evectionParams: {},
            evectionDetailType: '',   // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
            rule: {
                /*reason: [
                    { required: true, message: '请输入出差事由', trigger: 'blur' },
                ],*/
                feeAffiliationName: [
                    { required: true, message: '请选择成本归属', trigger: 'change' },
                    { validator: validateFeeAffiliationName, trigger: 'change' }
                ],
                projectName: [
                    {
                        required: true,
                        validator: validateProjectName, trigger: 'change'
                    }
                ],
                partnerEctionId: [
                    { required: true, message: '请输入出差单号', trigger: 'blur' },
                ]
            }
        }
    },
    components: {
        CostAttributionList,
        BelongToProjectList,
        DynamicFormPc
    },
    created() {},
    mounted() {
        let evectionParams = {
            vm: this
        }
        const evectionQuery = getEvectionAllType(evectionParams);
        this.evectionDetailType = evectionQuery.evectionDetailType;
    },
    activated() {},
    deactivated() {},
    destroyed() {},
    watch: {
        evectionFormParams: {
            handler (val) {
                console.log('触发evectionFormParams', val);
                this.evectionParams = val;
                if (this.evectionDetailType === '002' && !this.evectionParams.reason) {
                    this.evectionParams.reason = '商务出行'
                }
                console.log(this.evectionDetailType, this.evectionParams.feeAffiliationName);
                if (this.evectionDetailType === '002' && !this.evectionParams.feeAffiliationName) {
                    console.log('1112222333');
                    this.evectionParams.feeAffiliationType = 1;
                    this.evectionParams.feeAffiliationName = '各自所在部门';
                    this.evectionParams.feeAffiliationId = -1;
                    this.evectionParams.centerCode = '';
                }

            },
            deep: true
        },
        evectionParams: {
            handler (val) {
                if (val.centerCode != '' && val.centerCode != null && val.centerCode != undefined) {
                    setTimeout(() => {
                        this.$refs.belongToProjectListRef.init({
                            type: '002'
                        });
                    },100)
                }
                this.$emit('evectionChange', val)
            },
            deep: true
        }
    },
    computed: {},
    filters: {},
    methods: {
        handleSelectDelete() {
            this.$emit('handleSelectDelete');
        },
        handleSelectCallback(val) {
            this.$emit('handleSelectCallback', val);
        },
        init(value) {
            this.aDynamicFormPcInit(value);
            this.$refs.costAttributionList.init({
                type: '002'
            });
        },
        aDynamicFormPcInit(value){
            try {
                this.$refs.aDynamicFormPc.init({
                    oaNo: value.oaNo,
                    startDate: value.startDate,
                    userId: value.userId,
                    companyId: value.companyId,
                    type: 'edit',
                    businessType: value.businessType,
                    mock_data: {
                      enable: false
                    }
                }).then(res => {
                  this.parameterListBox = res;
                });
            } catch (e) {
                setTimeout(() => {
                    this.aDynamicFormPcInit();
                },500);
            }
        },
        // 检查表单
        checkForm() {
            return new Promise(resolve => {
                this.$refs['orderForm'].validate((valid) => {
                    // 动态表单
                    let formInfo = this.$refs.aDynamicFormPc.getData();
                    const params = {
                        checkResult: valid,
                        formInfo: formInfo
                    };
                    if (params.formInfo.centerCode != null && params.formInfo.centerCode.indexOf('E') != -1) {
                        let projectData = this.$refs.aDynamicFormPc.getDataProject();
                        this.evectionFormParams.projectCode = projectData.projectCode;
                        this.evectionFormParams.projectName = projectData.projectName;
                        if (this.evectionFormParams.projectCode == '') {
                            params.checkResult = false;
                            params.formInfo.checkForm = false;
                            this.$message({
                              type: "warning",
                              message: '请选择归属项目'
                            });
                        }
                    }
                    resolve(params)
                })
            })
        }
    }
}
